<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header
                    :title="$t('student_card')"
                    :isColumns="true"
                    @filter-div-status="datatable.filterStatus = $event"
                >
                    <template v-slot:columns>
                        <div
                            class="mb-1"
                            v-for="(column, key) in datatable.columns"
                            v-bind:key="key"
                        >
                            <b-form-checkbox
                                v-model="column.hidden"
                                :id="'checkbox-' + key"
                                :name="'checkbox-' + key"
                                :value="false"
                                :unchecked-value="true"
                                v-if="column.field != 'buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile
                    :title="$t('student_card')"
                    :isColumns="true"
                    @filter-div-status="datatable.filterStatus = $event"
                >
                    <template v-slot:columns>
                        <div
                            class="mb-1"
                            v-for="(column, key) in datatable.columns"
                            v-bind:key="key"
                        >
                            <b-form-checkbox
                                v-model="column.hidden"
                                :id="'checkbox-' + key"
                                :name="'checkbox-' + key"
                                :value="false"
                                :unchecked-value="true"
                                v-if="column.field != 'buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </HeaderMobile>
            </template>
            <datatable-filter
                v-show="datatable.filterStatus"
                @filter="filter"
                @filterClear="filterClear"
                :export-excel="true"
            >
                <b-row>
                    <b-col sm="6">
                        <b-form-group :label="$t('student_number')">
                            <b-form-input
                                v-model="
                                    datatable.queryParams.filter.student_number
                                "
                                min="0"
                                v-check-min-max-value
                            />
                        </b-form-group>
                    </b-col>
                    <b-col sm="6">
                        <b-form-group :label="$t('faculty')">
                            <faculty-selectbox
                                v-model="
                                    datatable.queryParams.filter.faculty_code
                                "
                            />
                        </b-form-group>
                    </b-col>
                    <b-col sm="6">
                        <b-form-group :label="$t('national_id')">
                            <b-form-input
                                v-model="
                                    datatable.queryParams.filter.national_id
                                "
                                min="0"
                                v-check-min-max-value
                            />
                        </b-form-group>
                    </b-col>
                    <b-col sm="6">
                        <b-form-group :label="$t('registration_type')">
                            <parameter-selectbox
                                v-model="
                                    datatable.queryParams.filter
                                        .registration_type
                                "
                                code="registration_types"
                            ></parameter-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6">
                        <b-form-group :label="$t('name')">
                            <b-form-input
                                v-model="datatable.queryParams.filter.name"
                                type="text"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col sm="6">
                        <b-form-group :label="$t('program')">
                            <program-selectbox
                                v-model="
                                    datatable.queryParams.filter.program_code
                                "
                            />
                        </b-form-group>
                    </b-col>
                    <b-col sm="6">
                        <b-form-group :label="$t('surname')">
                            <b-form-input
                                v-model="datatable.queryParams.filter.surname"
                                type="text"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col sm="6">
                        <b-form-group :label="$t('registration_academic_year')">
                            <academic-years-selectbox
                                v-model="
                                    datatable.queryParams.filter
                                        .registration_academic_year
                                "
                            />
                        </b-form-group>
                    </b-col>
                    <b-col sm="6">
                        <b-form-group :label="$t('card_status')">
                            <parameter-selectbox
                                v-model="
                                    datatable.queryParams.filter.card_status
                                "
                                code="card_status"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col sm="6">
                        <b-form-group :label="$t('registration_season')">
                            <semesters-selectbox
                                v-model="
                                    datatable.queryParams.filter
                                        .registration_semester
                                "
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable
                v-show="datatable.showTable"
                :isLoading="datatable.isLoading"
                :rows="datatable.rows"
                :columns="datatable.columns"
                :query-params="datatable.queryParams"
                :total="datatable.total"
                @on-per-page-change="onPerPageChange"
                @on-page-change="onPageChange"
            />
            <CommonModal ref="photoModal" size="md" :onHideOnlyX="true" @bHideModalHeaderClose="closeModal">
                <template v-slot:CommonModalContent>
                    <div id="printCard">
                        <img
                            v-if="asPrint"
                            :src="cardPhoto"
                            style="width: 100%"
                        />
                    </div>
                </template>
            </CommonModal>
            <CommonModal ref="uploadModal" size="md" :onHideOnlyX="true" @bHideModalHeaderClose="closeModal">
                <template v-slot:CommonModalContent>
                    <div>
                        <div class="embed-responsive embed-responsive-1by1 rounded mb-4 d-flex justify-content-center align-items-center text-muted bg-cover-center bg-gray-50"
                             :style="{backgroundImage: photo ? 'url(' + photo + ')':''}" v-if="!hasPhoto"></div>
                        <b-form-group :label="$t('photo')" class="mb-2">
                            <div class="d-flex custom-file-upload mb-2">
                                <b-form-file
                                    v-model="photoFile"
                                    :placeholder="$t('select_file')"
                                    :drop-placeholder="$t('drop_file')"
                                    ref="fileInput"
                                    accept="image/jpeg, image/png, image/gif"
                                >
                                </b-form-file>
                                <b-button v-if="hasPhoto" variant="danger" @click="clearPhoto">
                                    <i class="ri-close-fill"></i>
                                </b-button>
                                <b-button v-else variant="outline-secondary"
                                          @click="$refs.fileInput.$el.childNodes[0].click();">
                                    <i class="ri-add-fill"></i>
                                </b-button>
                            </div>
                            <b-img v-if="hasPhoto" :src="photoFileSrc" fluid block rounded></b-img>
                        </b-form-group>
                        <b-button variant="primary" block v-if="hasPhoto" @click="uploadPhoto">{{ $t('upload') }}</b-button>
                    </div>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox";
import ApprovedDeclinedSelectbox from "@/components/interactive-fields/ApprovedDeclinedSelectbox";
import AcademicYearsSelectbox from "@/components/interactive-fields/AcademicYearsSelectbox";
import SemestersSelectbox from "@/components/interactive-fields/SemestersSelectbox";
import StudentIdCardService from "@/services/StudentIdCardService";
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
import qs from "qs";
import CommonModal from "@/components/elements/CommonModal";
import toBase64 from "@/helpers/toBase64";
import StudentProgramService from "@/services/StudentProgramService";

const base64Encode = data =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

export default {
    components: {
        SemestersSelectbox,
        AcademicYearsSelectbox,
        ApprovedDeclinedSelectbox,
        ProgramSelectbox,
        FacultySelectbox,
        ParameterSelectbox,
        CommonModal,

        Datatable,
        DatatableFilter,

        HeaderMobile,
        Header,

        AppLayout,
    },
    metaInfo() {
        return {
            title: this.$t('student_card')
        };
    },
    data() {
        return {
            studentProgramId: null,
            photoFile: null,
            photoFileSrc: null,
            photo: null,
            asPrint: null,
            cardPhoto: null,
            datatable: {
                filterStatus: true,
                rows: [],
                columns: [
                    {
                        label: "",
                        field: "buttons",
                        sortable: false,
                        tdClass: "p-0 text-center w-40 align-middle",
                        buttons: [
                            {
                                text: this.$t("image_upload"),
                                class: "ri-gallery-upload-line align-middle top-minus-1 mr-3 text-muted",
                                callback: ({ id }) => {
                                    this.openUploadModal(id)
                                },
                            },
                            {
                                text: this.$t("create"),
                                class: "ri-image-add-line align-middle top-minus-1 mr-3 text-muted",
                                callback: ({ id }) => {
                                    this.previewStudentCard(id);
                                },
                            },
                            {
                                text: this.$t("download"),
                                class: "ri-file-download-line align-middle top-minus-1 mr-3 text-muted",
                                callback: ({ id }) => {
                                    this.printCard(id);
                                },
                            },
                        ],
                    },
                    {
                        field: "name",
                        label: this.toUpperCase("name"),
                        sortable: false,
                        hidden: false,
                    },
                    {
                        field: "surname",
                        label: this.toUpperCase("surname"),
                        sortable: false,
                        hidden: false,
                    },
                    {
                        field: this.getLocaleField("faculty_name"),
                        label: this.toUpperCase("faculty"),
                        sortable: false,
                        hidden: false,
                    },
                    {
                        field: this.getLocaleField("program_name"),
                        label: this.toUpperCase("program"),
                        sortable: false,
                        hidden: false,
                    },
                    {
                        field: "student_number",
                        label: this.toUpperCase("student_number"),
                        sortable: false,
                        hidden: false,
                    },
                    {
                        field: "national_id",
                        label: this.toUpperCase("national_id"),
                        sortable: false,
                        hidden: false,
                    },
                    {
                        field: this.getLocaleField("registration_type"),
                        label: this.toUpperCase("registration_type"),
                        sortable: false,
                        hidden: false,
                    },
                    {
                        field: "card_status",
                        label: this.toUpperCase("last_publication_date"),
                        sortable: false,
                        hidden: false,
                    },
                    {
                        field: "registration_academic_year",
                        label: this.toUpperCase("registration_academic_year"),
                        sortable: false,
                        hidden: false,
                    },
                    {
                        field: "registration_semester",
                        label: this.toUpperCase("registration_season"),
                        sortable: false,
                        hidden: false,
                    },
                ],
                queryParams: {
                    filter: {
                        faculty_code: null,
                        program_code: null,
                        student_number: null,
                        national_id: null,
                        registration_type: null,
                        card_status: null,
                        registration_academic_year: null,
                        registration_semester: null,
                    },
                    limit: 20,
                    page: 1,
                },
                total: 0,
                showTable: false,
                isLoading: false,
            },
        };
    },
    methods: {
        toBase64: toBase64,
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            let config = {
                params: { ...this.datatable.queryParams },
                paramsSerializer: (params) => {
                    return qs.stringify(params, { encode: false });
                },
            };

            StudentIdCardService.getAll(config)
                .then((res) => {
                    this.datatable.rows = res.data.data;
                    this.datatable.total = res.data.pagination.total;
                })
                .finally(() => (this.datatable.isLoading = false));
        },
        filter() {
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        filterClear() {
            let filterArr = Object.keys(this.datatable.queryParams.filter);
            for (const val of filterArr) {
                this.datatable.queryParams.filter[val] = null;
            }
            this.getRows();
        },
        async previewStudentCard(id) {
            try {
                let data = await StudentIdCardService.createCard(id);
                this.asPrint = true;
                this.cardPhoto = toBase64(data);
                this.$refs.photoModal.$refs.commonModal.show();
            } catch (err) {
                this.showErrors(err);
            }
        },
        async printCard(id) {
            try {
                let data = await StudentIdCardService.printCard(id);
                let b64=toBase64(data);
                const winHtml = '<html><body style="margin:0"><img src="'+b64+'" width="319"></body></html>';

                const winUrl = URL.createObjectURL(
                    new Blob([winHtml], { type: "text/html" })
                );

                let x = screen.width / 2 - 160;
                const win = window.open(
                    winUrl,
                    "Print",
                    "width=330,height=300,screenX="+x
                );
                win.print();
            } catch (err) {
                this.showErrors(err);
            }
        },
        closeModal() {
            this.$refs.photoModal.$refs.commonModal.hide();
            this.asPrint = null;
            this.cardPhoto = null;
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        openUploadModal(id){
            this.studentProgramId = id
            this.$refs.uploadModal.$refs.commonModal.show();
        },

        clearPhoto() {
            this.photoFile = null;
        },

        uploadPhoto() {
            if (!this.photoFile) {
                this.$toast.error(this.$t('api.' + response.data.message));
            }
            else {
                let formData = new FormData();
                formData.append('photo', this.photoFile)
                StudentProgramService.uploadPhoto(this.studentProgramId, formData).then(response => {
                    this.$toast.success(this.$t('api.' + response.data.message));
                }).catch(e => {
                    this.showErrors(e);
                }).finally(() => {
                    this.photoFile = null;
                    this.getPhoto();
                })
            }
        },

        getPhoto() {
            if (this.studentProgramId != null) {
                StudentProgramService.photo(this.studentProgramId).then(response => {
                    const base64 = this.toBase64(response);
                    if(base64.length<100){
                        this.photo = null;
                    } else {
                        this.photo = base64;
                    }
                }).catch(e => {
                    this.photo = null;
                })
            }
        },
    },

    computed: {
        hasPhoto() {
            return !!this.photoFile;
        }
    },

    watch: {
        photoFile(newValue, oldValue) {
            if (newValue !== oldValue) {
                if (newValue) {
                    base64Encode(newValue)
                        .then(value => {
                            this.photoFileSrc = value;
                        })
                        .catch(() => {
                            this.photoFileSrc = null;
                        });
                }
                else {
                    this.photoFileSrc = null;
                }
            }
        },

        studentProgramId: {
            handler(value) {
                this.getPhoto()
            },
            immediate: true
        },
    }
};
</script>
<style>
:root {
    --srcOfBackground: "";
}

@media print {
    #printCard {
        background-image: var(--srcOfBackground);
    }
}
</style>
