import Api from '@/services/Index';

const getAll = (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/student/cards', config);
}

const printCard = (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/student/cards/print/' + id,{responseType: 'arraybuffer'});
}

const createCard = (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/student/cards/create/'+id,{responseType: 'arraybuffer'});
}


export default {
    getAll,
    printCard,
    createCard
}
